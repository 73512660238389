import {en} from "./en";
import {fr} from "./fr";
import {ja} from "./ja";
import {es} from "./es";
import {de} from "./de";
import {ro} from "./ro";

export const resources = {
  en,
  fr,
  ja,
  es,
  de,
  ro
}
