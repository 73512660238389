import {initializeApp} from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from "firebase/auth";
import {axiosInstance} from "../api/AxiosInstance";
import {env} from "../env";
import {getMyIpInfo} from "../api/iplocation";
import moment from "moment-timezone";

const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_APIKEY,
  authDomain: env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: env.REACT_APP_FIREBASE_APPID,
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();


const signInWithGoogle = async (invitationToken = null) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    const userLanguage = navigator.language || navigator.userLanguage;
    const userLanguageSplitted = userLanguage.split("-");
    const language = userLanguageSplitted[0];

    let country = null;
    try {
      const ipInfo = await getMyIpInfo();
      country = ipInfo.countryCode;
    } catch (e) {
      country = userLanguageSplitted[1];
    }

    const payload = {
      id: user.uid,
      firebase_id: user.uid,
      display_name: user.displayName,
      // profile_pic_url: user.photoURL,
      auth_provider: "google",
      email: user.email,
      country: country,
      language: language,
      timezone: moment.tz.guess()
    };

    const queryParams = invitationToken
      ? `?invitation_token=${encodeURIComponent(invitationToken)}`
      : "";

    await axiosInstance.post(`/users/signup${queryParams}`, payload);
  } catch (err) {
    console.error(err);
  }
};

const signInWithEmailLinkInternal = async (user) => {
  try {
    // Get the email from the URL query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("emailForSignIn");

    if (!email) {
      throw new Error("No email found in URL query parameters for sign-in.");
    }

    // Determine user language
    const userLanguage = navigator.language || navigator.userLanguage;
    const [language, region] = userLanguage.split("-");

    // Get user's country information
    let country;
    try {
      const ipInfo = await getMyIpInfo();
      country = ipInfo.countryCode || region;
    } catch (error) {
      console.warn("Failed to fetch IP information, falling back to region.");
      country = region;
    }

    // Extract display name from email before "@"
    const displayNameFromEmail = email.split("@")[0];

    // Prepare the payload for signup
    const payload = {
      id: user.uid,
      firebase_id: user.uid,
      display_name: displayNameFromEmail || "Anonymous",
      auth_provider: "local",
      email: user.email,
      country: country,
      language: language || "en",
      timezone: moment.tz.guess(),
    };

    // Send the payload to the server
    await axiosInstance.post("/users/signup", payload);
    console.log("User signup payload successfully sent.");
  } catch (error) {
    console.error("An error occurred during sign-in or signup:", error);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const registerWithEmailAndPassword = async (display_name, email, password, country, language, timezone, invitation_token) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    const queryParams = invitation_token
      ? `?invitation_token=${encodeURIComponent(invitation_token)}`
      : "";

    const payload = {
      id: user.uid,
      display_name: display_name,
      auth_provider: "local",
      email: email,
      country: country,
      language: language,
      timezone: timezone
    };

    await axiosInstance.post(`/users/signup${queryParams}`, payload);

  } catch (err) {
    console.error(err);
    throw err;
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const logout = () => {
  signOut(auth);
  window.location.replace("/login");
};

export {
  app,
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  signInWithEmailLinkInternal
};
